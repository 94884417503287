.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the height of the loader container */
}

.logo-loader {
    animation: rotate 2s linear infinite; /* Adjust the animation duration and type */
}

@keyframes rotate {
    from {
        transform: rotate(0deg); /* Start with no rotation */
    }

    to {
        transform: rotate(360deg); /* End with a full rotation */
    }
}

.qr-code-builder {
    /*font-family: 'Arial, sans-serif';*/
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.option label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.option input[type="text"],
.option input[type="color"],
.option select {
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
}

.option input[type="file"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.option input[type="range"] {
    width: 100%;
}

.qr-code-preview {
    margin-top: 20px;
}
.restaurant-visit-wrapper {
    /* Add your desired styles here */
    display: flex; /* Make the element behave like an inline button */
    justify-content: center;
    align-items: center;
    padding: 5px 10px; /* Add some padding for spacing */
    border-radius: 5px; /* Add rounded corners */
    background-color: #eee; /* Set a background color */
    cursor: pointer; /* Make it look clickable */
    margin-top: 10px; /* Add some margin from the top */
}

.NormalOption input[type="file"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.qr-code-container {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
}
    .qr-code-container.default {
        background-color: white;
        padding: 10px;
        border-radius: 10px;
    } 
.ChartCSS{  
}

.wifi-icon {
    font-size: 24px;
}

.wifi-password-container1 {
    justify-content: center; /* Horizontally center items */
    align-items: center; /* Vertically center items */
    display: flex;
}

.wifi-password-container {
    text-align: center;
    color: #f9f9f9;
    max-width: 300px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

    .wifi-password-container h2 {
        color: #f9f9f9;
    }

    .wifi-password-container p {
        margin: 10px 0;
    }


.OpeningHoursDiv {
    max-width: 1000px; /* Set your desired maximum width */
    margin: 0 auto; /* Center the container horizontally */
    width: 100%; /* Ensure the container takes the full width if the content is smaller than max-width */
}

.ModalImageText {
    color: rgb(28, 69, 89);
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    /* font-family:Tahoma, Verdana, sans-serif ,;  */
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.image-cover-container {
    position: relative;
    width: 100%;
    height: 100%; /* You can adjust the height as needed */
    overflow: hidden;
}

.OriginsSoftwaresDiv {
    font-weight: bold;
    color: white;
}

.modal-content {
    font-weight: bold;
    background-color: whitesmoke;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .modal-content img {
        max-width: 100%;
        max-height: 100%; /* Set a maximum height based on 80% of the viewport height */
        object-fit: contain; /* Maintain aspect ratio and fit inside the container */
    }


.Modalprencipale {
}

.CoverImageMax {
    width: 80%;
    height: 70%;
    object-fit: cover;
}

.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.image-cover-container:hover .cover-image {
    transform: scale(1.5); /* You can adjust the zoom level */
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.Navbar {
    background-color: #021E39;
    flex: 100%;
    width: 100%;
    height: 60px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
}

    .Navbar .leftSide {
        flex: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
    }

        .Navbar .leftSide .links {
            max-height: 60px;
        }

            .Navbar .leftSide .links a {
                text-decoration: none;
                color: white;
                font-size: 30px;
                margin-left: 12px;
            }

    .Navbar .rightSide {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: 20px;
    }

        .Navbar .rightSide .links {
            max-height: 25px;
        }

            .Navbar .rightSide .links a {
                text-decoration: none;
                color: white;
                font-size: 20px;
                margin-left: 30px;
            }

.AllignFooter {
    text-align: Center;
}

.footerRate {
    text-align: Center;
}
.AshtawAsalDivOverrideLogo { 
}
.Restimg-Position {
    max-width: 80%;
    height: auto;
    margin-left: 40px;
}

.Restimg-Position_Saha-W-Hana-FornHara {
    max-width: 100%;
    height: auto;
    width:100%;
    margin-left: 0; 
}
.Restimg-Position_SewarRestoCafe { 
    max-width: 100%;
    height: auto;
    margin-left:0;
}
.Restimg-Position_SkyLineRestoCafe {
    max-width: 100%;
    height: auto;
    margin-left: 0;
}
.Restimg-Position_PAZZA {
    margin-top: -80px;
    max-width: 100%;
    height: auto;
    margin-left: 0;
}
.Restimg-Position_AchtawAssal { 
    max-width: 100%;
    height: auto;
    margin-left:0;
    margin-top:-50px;
}
.Restimg-Position_SajWmaSaj {
    max-width: 100%;
    max-height: 100%;
}

.Restimg-Position_ShoufHighLand {
    width: fit-content;
    height: fit-content;
    width: 100%;
    height: 100%;
}

.Restimg-Position_HazeenCafe {
    position: absolute; /* Positioning inside HazeenCafe-block */
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centering */
    z-index: 2; /* Ensure it's below the text if needed */
    margin-top: 50px;
}

.Restimg-Position_Nostalgie {
    width: fit-content;
    height: fit-content;
    width: 100%;
    height: 100%;
}

.Restimg-Position_Arena {
    max-width: 100%;
    max-height: 100%;
}

.Restimg-Position_MhannaTyr {
    width: 100%;
    max-height: 100%;
}

.ParagraphTitle {
    text-align: Center;
    font-family: 'Impact';
    margin: 10px;
    padding: 5px;
    font-size: 30px;
}

.btnGrpTranslation:hover {
    background-color: red; /* Updated background color on hover */
    transform: translateY(-1px); /* Move the button up slightly on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* Adjusted box shadow on hover */
}

.btnGrpTranslation {
    justify-content: center;
    width: 80%;
    background-color: darkred;
}

.margintoleftshway {
    justify-content: center;
}

.TEXTPriceBulk {
    min-width: 120px;
    max-width: 120px;
}

.BorderForBtnCat {
    border: 1px solid white;
}

.ForEachCatBtn {
    min-width: 120px;
}

.ForEachSubCatBtn {
    min-width: 75px;
    display: inline-block;
}

.CatDivInsideRow {
    margin: 1px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: rgb(36,90,118);
    /* border:  0.001rem inset white; */
}

.SubCatDivInsideRow {
    margin: 1px;
    border-radius: 5px;
    border: 1px solid #ddd;
    /*background-color: rgb(36,90,118);*/
    /* border:  0.001rem inset white; */
}

.CatFont {
    color: white;
    text-transform: capitalize;
}

.DivCatBtn {
    margin: 1px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: rgb(36,90,118);
}

.MultiLinesTextBox {
    width: 260px;
}

.SubCatImg {
    object-fit: cover;
    width: 60px;
    height: 60px;
    border-radius: 15%;
}

.SubCatImg1 {
    object-fit: cover;
    width: 80px;
    height: 80px;
    border-radius: 15%;
}

.RestInfoDescTextStyle {
    font-size: large;
    color: rgb(28, 69, 89)
}

.LinkKatibeinButtons {
    /* color:rgb(252,171,31);  */

    font-size: medium;
    /*color: orange;*/
    text-transform: capitalize;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.katibeta7tlsoura {
    display: inline-block;
}

.btnArTrans {
    width: 100%;
}

.ColorTextLang {
    color: rgb(28, 69,89);
}

.btnEngTrans {
    width: 100%;
}

.oneCategorieImage {
    text-align: Center;
    height: auto;
}
.totalPriceViewOrder {
    padding: 1px;
    font-weight: bold;
    background: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CatRow {
    border: 1px solid white;
    text-align: left;
    background-color: rgb(28, 69,89);
    border-radius: 20px;
}

.CatPosonTopDiv {
    /* margin-left:-20px; */
    background-color: white;
    color: white;
}

.CatPosonTop {
    background-color: white;
    color: white;
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 0;
    z-index: 1;
}

.EnglishCat {
    border: 1px solid white;
    text-align: left;
    /*background-color: rgb(28, 69,89);*/
    border-radius: 20px;
}

.ArabicCat {
    border: 1px solid white;
    text-align: right;
    background-color: rgb(28, 69,89);
    border-radius: 20px;
}

.EnglishSubCat {
    border: 1px solid white;
    text-align: left;
    /*background-color: rgb(28, 69,89);*/
    border-radius: 20px;
}

.ArabicSubCat {
    border: 1px solid white;
    text-align: right;
    /*background-color: rgb(28, 69,89);*/
    border-radius: 20px;
}

.divsearch {
    max-width: 600px;
    text-align: center;
}

.SearchItems {
    width: 150px;
}

.top-right-button {
    position: absolute; /* Position relative to the container */
    top: 10px; /* Adjust as needed for spacing */
    right: 10px; /* Adjust as needed for spacing */
}

.container {
    position: relative; /* Establish a positioning context */
}

.ExpandDivMenuItems { 
    color: white;
    /*background: white;*/
}

.TxtSearch {
    color: white;
    width: 150px;
}

.ArabicItemRow {
    display: flex;
    flex-direction: row-reverse;
    /* padding-left: 15px;   */
    /* padding-right: 10px; */
    /* padding-bottom: 15px;  */
}

.EnglishItemRow {
    display: flex;
    flex-direction: row;
    /* padding-left: 500px;   */
    /* padding-right: 15px; */
    /* padding-bottom: 5px;   */
}

.DivBetweenItems {
    margin-top: -18px;
    /* height: 40px; */
}

hr.space-1 {
    background: transparent;
    color: transparent;
    margin: 0;
    border-style: none;
    height: 5vw;
}
.ArrowSubCat {
}
.DivSizeGuide {
    max-width: 600px;
    margin-left: 25px;
    margin-Right: 25px;
    color: white;
    display: flex;
    justify-content: space-between; /* Adjust this based on your design preferences */
}

.SizeGuide1Unit {
    /* Add any additional styling for the labels */
}

.DescriptionItem {
    font-size: small;
    /* font-weight: 0; */
    font-family: serif
}
.ArabicDescriptionItem {
    /*color:white;*/
    direction: rtl;
    font-size: small;
    /* font-weight: 0; */
    font-family: serif
}

.FontPrice {
    /*color: white;*/
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.FontCurr {
    /*color: white;*/
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.PricePosEng {
    height: fit-content; 
    /* margin-top: -5px; */
    /* margin-left: 18px; */
    /* margin-left: 30px;  */
}

.PricePosAr { 

    /* margin-right: 30px;   */
}


.CheckOrderGrid {
    height: 400;
    width: 500;
}

.EnglishFontItem {
    /*color: white;*/
    text-align: left;
    font-weight: 500;
    font-size: medium;
    /* font-family:Tahoma, Verdana, sans-serif ,;  */
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.ArabicFontItem {
    /*color: white;*/
    text-align: right;
    font-weight: 500;
    /* font-weight: bold; */
    font-size: medium;
    /* font-family:Tahoma, Verdana, sans-serif ,;  */

    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.ArabicImageandItemName {
    display: flex;
    flex-direction: row-reverse;
    min-width: 75%;
    /* padding-right: 10px;     */
}

.EnglishmageandItemName {
    min-width: 75%;
    display: flex;
    flex-direction: row;
}

.CatTitleArabic {
    /*background-color: rgb(36,90,118);*/
    color: rgb(252,171,31); 
    text-align: center;
}

.CatTitleEnglish {
    /*background-color: rgb(36,90,118);*/
    color: rgb(252,171,31); 
    text-align: center;
}

.ItemsImage {
    margin-left:2px;
    object-fit: cover;
    width: 80px;
    height: 80px;
    border-radius: 20%;
}

.ViewOrderDiv {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: white;
    text-align: right;
    z-index: 1;
}

.remaining-days-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.remaining-days {
    background: linear-gradient(145deg, #e0e0e0, #f8f8f8);
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    max-width:600px; /* Set a maximum width to prevent the card from becoming too wide on larger screens */
    width: 99%; /* Ensure the card takes up the full width of its container */
}

.duration-icon { 
    margin-right: 10px; 
    color:darkgray;
}

  

.remaining-days-label {
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
}

.remaining-days-label-red {
    color: red;
}

.remaining-days-label-green {
    color: green;
}

.days-count {
    font-size: 20px;
    color: #888;
    display: block;
    animation: fadeInUp 0.5s ease;
}

.days-count-red {
    color: red;
}

.days-count-green {
    color: green;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .remaining-days {
        flex-direction: column; /* Stack elements vertically on smaller screens */
        padding: 20px 15px; /* Reduce padding for smaller screens */
    }

    .duration-icon {
        margin-right: 0;
        margin-bottom: 5px; /* Add space between icon and text on smaller screens */
    }
}


.MuiDataGrid-root {
    height: 100%;
    border: 5px solid rgba(224, 224, 224, 1)
}

.Ayre {
    background-color: black;
    color: black;
}

.ParentDivOfItemRow {
    width: 80%;
    justify-content: space-between;
}

.selecteddiv {
    background: rgb(235, 235, 237);
}
 
.custom-font-size .MuiDataGrid-cell,
.custom-font-size .MuiDataGrid-columnHeaderTitle {
    padding:0px !important;
    font-size: small; /* Adjust the font size as needed */
}

.ItemsRoot { 
    max-width: 800px; 
}

.loaderZindex {
    z-index: 1;
}

.KingFoodtext-block {
    width: 80%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 5%; /* Adjust this value to move the positioned div up and down */
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    color: black;
    font-weight: bold;
}

.Mhannatext-block {
    width: 80%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 5%; /* Adjust this value to move the positioned div up and down */
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    color: black;
    font-weight: bold;
}

.ShoufHighLand-block {
    width: 80%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 2.5%; /* Adjust this value to move the positioned div up and down */
    background: rgba(65, 38, 38, 0.178);
}

.HazzenCafe-block {
    position: relative; /* Parent must be relative for absolute positioning */
    opacity: 0.65; /* Adjust opacity for better visibility */
    width: 100%;
    height: 50vh; /* Full viewport height */
    background: rgba(255, 255, 249, 0.3); /* Light background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents any overflow */
    margin-top:30px;
    /*z-index: 999;*/
}

.PAZZA-block {
    padding-top: 40px;
    opacity: 0.95;
    width: 80%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0%;
    border-radius: 30%; /* Make the border circular */
}
.SkyLineRestoCafe-block {

    padding-top: 100px;
    opacity: 0.96;
    width: 100%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0%; /* Adjust this value to move the positioned div up and down */
    /*background: rgba(255, 255, 249, 0.178);*/
}
.SewarRestoCafe-block {

    padding-top: 40px;
    opacity: 0.98;
    width: 60%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0%; /* Adjust this value to move the positioned div up and down */
    /*background: rgba(255, 255, 249, 0.178);*/
}
.Saha-W-hanaFornhara-block {

    padding-top: 40px;
    opacity: 0.91;
    width: 65%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0%; /* Adjust this value to move the positioned div up and down */
    /*background: rgba(255, 255, 249, 0.178);*/
}
.Achta-W-Assal-block {

    padding-top: 80px;
    opacity: 0.90;
    width: 70%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0%; /* Adjust this value to move the positioned div up and down */
    background: rgba(255, 255, 249, 0.178);
}


.Nostalgie-block {
    padding-top: 30px;
    opacity: 0.80;
    width: 80%;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0%; /* Adjust this value to move the positioned div up and down */
    background: rgba(255, 255, 249, 0.178);
}

.Tabs { 
    color: rgb(36,90,118);
    /* background-color: rgba(252, 177, 3); */
}

@media only screen and (min-width:1000px) {

    .btnGrpTranslation {
        width: 50%;
    }

    .Restimg-Position {
        max-width: 10%;
        height: auto;
    }

    .Tabs {
        margin-left: 200px;
    }

    .ParagraphTitle {
        font-size: 50px;
    }
}

@media only screen and (min-width:750px) {
    .Restimg-Position_SajWmaSaj {
        width: 40%;
        height: 40%;
    }
    .Restimg-Position_AchtawAssal {
        width: 300px;
        height: 300px;
    }
    .Restimg-Position_Saha-W-Hana-FornHara {
        width: 300px;
        height: 300px;
    }
    .Restimg-Position_PAZZA {
        width: 300px;
        height: 300px;
    }
    .Restimg-Position_SkyLineRestoCafe {
        width: 300px;
        height: 300px;
    }
    .Restimg-Position_SewarRestoCafe {
        width: 300px;
        height: 300px;
    }
    .SewarRestoCafe-block {
        display: none;
    }
    .SkyLineRestoCafe-block {
        display: none;
    }
    .PAZZA-block {
        display: none;
    }
    .Saha-W-hanaFornhara-block {
        display: none;
    }
    .Achta-W-Assal-block {
        display: none;
    }
  
    .Restimg-Position_Nostalgie {
        width: 40%;
        height: 40%;
    }
}

@media only screen and (min-width:550px) {

    .ColorTextLang {
        font-size: large;
    }

    .SubCatImg {
        width: 50px;
        height: 50px;
        margin-top: -5px;
    }

    .ItemsImage {
        width: 120px;
        height: 120px;
    }

    .EnglishFontItem {
        font-size: large;
    }

    .ArabicFontItem {
        font-size: large;
    }

    .DescriptionItem {
        font-size: medium;
    }
    .ArabicDescriptionItem {
        direction: rtl;
        font-size: medium;
    }
}

@media only screen and (max-width:950px) {

    .Navbar .leftSide .links a {
        font-size: 13px;
    }
}

@media only screen and (max-width:350px) {
    .PricePosEng {
        margin-left: 0px;
        /* margin-top: -10px; */
    }

    .PricePosAr {
        margin-right: 0;
        /* margin-top: -20px; */
    }

    .Navbar .leftSide .links a {
        font-size: 8px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 576px) {
    .BtnUpdatePriceBulk {
    }

    .footer {
        text-align: Center;
    }

    .logosInfo {
        margin-left: -200px;
    }
}
















































/* i tooook this fucnkin css from a website ony to run fne originsoft page */




/*!

 =========================================================
 * Paper Kit React - v1.3.0 based on Paper Kit - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*      light colors - used for select dropdown         */
/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */


.card-image.card-big-shadow {
    max-width: 100%;
}

.card-image .card {
    background-size: cover;
    min-height: 430px;
    width: 100%;
}

.card-image .img-raised {
    margin-top: -20px;
}

.page-header .card-pricing.card-plain .card-category,
.page-header .card-pricing.card-plain .card-title {
    color: #FFFFFF;
}

.card-plain .card-avatar {
    margin-top: 0;
}

.card-refine .panel-title {
    font-size: 1em;
}

.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
}

    .card-plain .card-body {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-plain img {
        border-radius: 12px;
    }

.card-register {
    background-color: #FF8F5E;
    border-radius: 8px;
    color: #fff;
    max-width: 350px;
    margin: 20px 0 70px;
    min-height: 400px;
    padding: 30px;
}

    .card-register label {
        margin-top: 15px;
    }

    .card-register .card-title {
        color: #B33C12;
        text-align: center;
    }

    .card-register .btn {
        margin-top: 30px;
    }

    .card-register .forgot {
        text-align: center;
    }

    .card-register .input-group-text {
        border: 1px solid #FFFFFF;
    }

.card-profile {
    margin-top: 30px;
    text-align: center;
}

    .card-profile .card-cover {
        height: 130px;
        background-position: center center;
        background-size: cover;
        border-radius: 12px 12px 0 0;
    }

    .card-profile .card-body .card-title {
        margin-top: 5px !important;
    }

    .card-profile .card-body .card-category {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .card-profile .card-avatar {
        max-width: 120px;
        max-height: 120px;
        margin: -60px auto 0;
        border-radius: 50%;
        overflow: hidden;
    }

        .card-profile .card-avatar.border-white {
            border: 4px solid #FFFFFF;
        }

        .card-profile .card-avatar.border-gray {
            border: 4px solid #ccc;
        }

.section .card-profile {
    margin-top: 100px;
}

.landing-alert {
    margin-bottom: 0;
}

.page-header {
    background-color: #B2AFAB;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .page-header .filter::after {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .page-header.page-header-small {
        min-height: 65vh !important;
        max-height: 700px;
    }

    .page-header.page-header-xs {
        min-height: 40vh !important;
    }

    .page-header.section-dark .content-center,
    .page-header.section-dark .category-absolute {
        z-index: 1;
    }

    .page-header .motto {
        text-align: left;
        z-index: 3;
        color: #fff;
        position: relative;
    }

.landing-section {
    padding: 100px 0;
    z-index: 1;
}

.ayre {
    color: #fff;
}

.section-buttons {
    z-index: 1;
}

.landing-section .btn-simple {
    padding: 0;
}

.landing-section .column {
    padding: 0 75px 0 25px;
}

.team-player .img-circle, .team-player .img-thumbnail {
    display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}

.contact-form {
    margin-top: 30px;
}

    .contact-form label {
        margin-top: 15px;
    }

    .contact-form .btn {
        margin-top: 30px;
    }

.navbar-relative {
    position: relative;
    z-index: 2;
}

#register-navbar a {
    color: #FFF;
}

.register-background .container {
    margin-top: 11%;
    position: relative;
    z-index: 3;
}

.register-footer {
    bottom: 20px;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: transparent;
    color: #FFFFFF;
}

    .register-footer .fa-heart {
        color: #EB5E28;
    }

.register-card label {
    margin-top: 15px;
}

.register-card .title {
    color: #B33C12;
    text-align: center;
}

.register-card .btn {
    margin-top: 30px;
}

.register-card .forgot {
    text-align: center;
}

.profile-content {
    position: relative;
}

.owner {
    text-align: center;
}

    .owner .avatar {
        padding: 15px;
        max-width: 180px;
        margin: -85px auto 0;
        display: inline-block;
    }

    .owner .name h4 {
        margin-top: 10px;
    }

.profile-tabs {
    margin: 50px 0;
    min-height: 300px;
}

#following h3 {
    margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox {
    margin-top: -15px;
}

#follows .follows h6 {
    margin-top: 15px;
}

.follows hr {
    margin-top: 10px;
}

.title-brand {
    max-width: 730px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    color: #FFFFFF;
    display: block;
}

    .title-brand .type {
        position: absolute;
        font-size: 20px;
        background: #132026;
        padding: 6px 10px;
        border-radius: 4px;
        top: 0;
        font-weight: 600;
        margin-top: 10px;
        right: -15px;
    }

.presentation-title {
    font-size: 5em;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    background: #fbf8ec;
    background: -moz-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
    background: -ms-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
    background: linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.presentation-subtitle {
    font-size: 1.7em;
    color: #FFFFFF;
}

.category-absolute {
    position: absolute;
    text-align: center;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
}

    .category-absolute .creative-tim-logo {
        max-width: 140px;
        top: -2px;
        left: 3px;
        position: relative;
    }

.fog-low {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -35%;
    margin-bottom: -50px;
    width: 110%;
    opacity: .85;
}

    .fog-low img {
        width: 100%;
    }

    .fog-low.right {
        margin-left: 30%;
        opacity: 1;
    }


.item-container {
 
}



/*:root {
    --box-shadow-color: rgba(255, 0, 0, 0.1);*/ /* Default shadow color */
/*}*/
.item-wrapper { 
    margin-top: 5px;
    border-radius: 12px; /* Rounds the corners of the item wrapper */
    /*  box-shadow: 0 1px 2px white,
        0 -2px 2px white,
        -2px 0 2px white,
        2px 0 2px white;
*/
    /*
    box-shadow: 0 2px 1px rgba(255, 255, 255, 0.1),
        0 -1px 1px rgba(255, 255, 255, 0.1),
        -1px 0 1px rgba(255, 255, 255, 0.1),
        1px 0 1px rgba(255, 255, 255, 0.1);*/
    ;
    /*box-shadow: 0 4px 8px blue, 0 6px 20px red;*/
    /* Creates a subtle shadow around the item using semi-transparent black */
    overflow: hidden; /* Ensures child elements don't overflow the rounded corners */
    /*background-color: white;*/
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-left: 2%; /* Adjust this value to push the border from the left */
    margin-right: 2%; /* Adjust this value to push the border from the right */
    width: 96%; /* Adjust the width to accommodate the margin */
}

    .item-wrapper:hover {
        transform: translateY(-2px); /* Moves the item up slightly on hover for a 3D effect */
        /*box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.2);*/
        /* Enhances the shadow on hover for a more pronounced 3D effect */
    }
.LisItemItemRow {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
 
     

/* Retain original color styles */

 

/* Other relevant styles */

/*# sourceMappingURL=paper-kit.css.map */
